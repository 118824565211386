<template>
  <div>
    <div class="d-flex justify-center mt-0">
      <v-radio-group
        v-model="selectedWsType"
        row
        mandatory
        @change="changeRadioBtn"
      >
        <v-radio
          label="Archive"
          :ripple="true"
          value="Archive"
          color="color_green"
        />
        <v-radio
          label="Real-Time"
          :ripple="true"
          value="Real-Time"
          color="color_green"
        />
        <v-radio
          label="Forecast"
          :ripple="true"
          value="Forecast"
          color="color_green"
        />
      </v-radio-group>
    </div>
    <v-data-table
      v-model="wsSelected"
      :items.sync="wsLayersSelectedShallow"
      :headers="geoserver_data.layerHeaders"
      class="elevation-0 pa-5"
      dense
    />
    <div v-if="wsLayersSelectedShallow.length > 0">
      <v-row>
        <v-col :cols="intervalCols">
          <strong class="mb-2">Time step</strong>
        </v-col>
        <v-col :cols="intervalCols">
          <strong class="mb-2">Time interval</strong>
          <v-radio-group
            v-if="selectedWsType === 'Archive'"
            v-model="changeStaticOrDynamic"
            row
            mandatory
          >
            <v-radio
              label="Static"
              :ripple="true"
              value="Static"
              color="color_green"
            />
            <v-radio
              label="Dynamic"
              :ripple="true"
              value="Dynamic"
              color="color_green"
              @click="startDateArchive = ''"
            />
          </v-radio-group>
        </v-col>
        <v-col
          v-if="selectedWsType !== 'Archive'"
          cols="4"
        >
          <strong class="mb-2">Refresh interval</strong>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col :cols="intervalCols">
          <v-btn-toggle
            v-model="toggle_exclusive_col_1"
            class="d-flex flex-wrap"
            mandatory
          >
            <v-btn
              small
              @click="clickMonth('col_1')"
            >
              <v-icon>mdi-calendar-month</v-icon>
              Months
            </v-btn>
            <v-btn
              small
              @click="clickDay('col_1')"
            >
              <v-icon>mdi-calendar-today</v-icon>
              Days
            </v-btn>
            <v-btn
              small
              @click="clickHour('col_1')"
            >
              <v-icon>mdi-hours-24</v-icon>
              Hours
            </v-btn>
            <v-btn
              small
              @click="clickMinutes('col_1')"
            >
              <v-icon>mdi-av-timer</v-icon>
              Min
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          v-if="selectedWsType === 'Real-Time' || selectedWsType === 'Forecast' || (selectedWsType === 'Archive' && changeStaticOrDynamic === 'Dynamic')"
          v-model="toggle_exclusive_col_2"
          mandatory
          :cols="intervalCols"
        >
          <v-btn-toggle
            v-model="toggle_exclusive_col_2"
            class="d-flex flex-wrap"
            mandatory
          >
            <v-btn
              small
              @click="clickMonth('col_2')"
            >
              <v-icon>mdi-calendar-month</v-icon>
              Months
            </v-btn>
            <v-btn
              small
              @click="clickDay('col_2')"
            >
              <v-icon>mdi-calendar-today</v-icon>
              Days
            </v-btn>
            <v-btn
              small
              @click="clickHour('col_2')"
            >
              <v-icon>mdi-hours-24</v-icon>
              Hours
            </v-btn>
            <v-btn
              small
              @click="clickMinutes('col_2')"
            >
              <v-icon>mdi-av-timer</v-icon>
              Min
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          v-if="selectedWsType !== 'Archive'"
          cols="4"
        >
          <v-btn-toggle
            v-model="toggle_exclusive_col_3"
            class="d-flex flex-wrap"
            mandatory
          >
            <v-btn
              small
              @click="clickHour('col_3')"
            >
              <v-icon>mdi-hours-24</v-icon>
              Hours
            </v-btn>
            <v-btn
              small
              @click="clickMinutes('col_3')"
            >
              <v-icon>mdi-av-timer</v-icon>
              Min
            </v-btn>
            <v-btn
              small
              @click="clickSeconds('col_3')"
            >
              <v-icon>mdi-camera-timer</v-icon>
              Sec
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="intervalCols">
          <v-row>
            <v-col>
              <v-text-field
                v-model="animationStep"
                outlined
                dense
                full-width
                :label="`Time step`"
                :suffix="`/${dynamicTime_col_1}`"
                @keyup="savePastTime('col_1')"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="intervalCols">
          <v-row>
            <v-col v-if="selectedWsType === 'Real-Time' || (selectedWsType === 'Archive' && changeStaticOrDynamic === 'Dynamic')">
              <v-text-field
                v-model="animationLimitPast"
                outlined
                dense
                full-width
                :label="`${dynamicTime_col_2} in the past`"
                :suffix="`/${dynamicTime_col_2}`"
                @keyup="savePastTime('col_2')"
              />
            </v-col>
            <v-col v-else-if="selectedWsType === 'Forecast'">
              <v-text-field
                v-model="animationLimitFuture"
                outlined
                dense
                full-width
                :label="`${dynamicTime_col_2} in the future`"
                :suffix="`/${dynamicTime_col_2}`"
                @keyup="saveFutureTime()"
              />
            </v-col>
            <v-col v-else>
              <v-menu
                ref="menuStartArchive"
                v-model="menuStartArchive"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDateArchive"
                    label="Start date"
                    append-icon="mdi-calendar"
                    readonly
                    dense

                    outlined
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="startPicker"
                  v-model="startDateArchive"
                  no-title
                  :show-current="false"
                  min="1900-01-01"
                  @change="saveArchiveDateStart"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="selectedWsType !== 'Archive'"
          cols="4"
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="refreshIntervalStep"
                outlined
                dense
                full-width
                :label="`Refresh interval`"
                :suffix="`/${refreshIntervalType}`"
                @keyup="savePastTime('col_3')"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    wsLayersSelected: {
      type: Array,
      default: () => []
    },
    wsForm: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      toggle_exclusive_col_1: null,
      toggle_exclusive_col_2: null,
      toggle_exclusive_col_3: null,
      selectedWsType: null,
      changeStaticOrDynamic: null,
      currentRealTimeDate: null,
      startDateArchive: null,
      menuStartArchive: null,
      dynamicTime_col_1: 'Days',
      dynamicTime_col_2: 'Days',
      animationStep: 0,
      animationLimitPast: 0,
      animationLimitFuture: 0,
      refreshIntervalStep: 0,
      dateType_col_1: 'dynamic_days',
      dateType_col_2: 'dynamic_days',
      refreshIntervalStepType: '',
      refreshIntervalType: '',
      wsSelected: [],
      wsLayersSelectedShallow: []
    };
  },
  computed: {
    ...mapState(['services', 'wsService', 'geoserver_data', 'layers']),
    wsConfigFiltered() {
      return this.wsLayersSelected.filter(ws => ws.ws_group === this.selectedWsType)[0];
    },
    intervalCols() {
      return this.selectedWsType !== 'Archive' ? 4 : 6;
    }
  },
  watch: {
    $route() {}
  },
  mounted() {
    if (this.wsLayersSelected) {
      this.selectedWsType = this.wsLayersSelected[0].ws_group;
    }

    if (this.wsService.wsConfig.length > 0) {
      this.wsService.wsConfig.filter(existingWs => {
        this.wsLayersSelected.forEach(ws => {
          if (existingWs.ws_id === ws.ws_id) {
            if (existingWs.ws_group === 'Archive' && existingWs.archive_start_date === '' && existingWs.animation_limit_past !== '0') {
              this.changeStaticOrDynamic = 'Dynamic';
            }

            ws.animation_step = existingWs.animation_step;
            ws.animation_step_date_type = existingWs.animation_step_date_type;
            ws.animation_limit_past = existingWs.animation_limit_past;
            ws.animation_limit_future = existingWs.animation_limit_future;
            ws.animation_limit_date_type = existingWs.animation_limit_date_type;
            ws.archive_start_date = existingWs.archive_start_date;
            ws.refresh_interval_step = existingWs.refresh_interval_step;
            ws.refresh_interval_step_type = existingWs.refresh_interval_step_type;
          }
        });
      });
    }

    this.changeRadioBtn();
  },
  methods: {
    ...mapActions([]),
    changeRadioBtn() {
      this.wsLayersSelectedShallow = JSON.parse(JSON.stringify(this.wsLayersSelected));
      this.wsLayersSelectedShallow = this.wsLayersSelectedShallow.filter((item) => item.ws_group === this.selectedWsType
      );

      if (this.wsLayersSelectedShallow.length > 0) {
        this.checkAnimationStepLimitValues();
        this.savePastTime('col_1');

        if (this.selectedWsType === 'Forecast') {
          this.saveFutureTime();
        } else {
          this.savePastTime('col_2');
        }
      }
    },
    checkAnimationStepLimitValues() {
      this.animationStep = this.wsConfigFiltered.animation_step;
      this.animationLimitPast = this.wsConfigFiltered.animation_limit_past;
      this.animationLimitFuture = this.wsConfigFiltered.animation_limit_future;
      this.startDateArchive = this.wsConfigFiltered.archive_start_date;
      this.refreshIntervalStep = this.wsConfigFiltered.refresh_interval_step;

      switch (this.wsConfigFiltered.animation_step_date_type) {
        case 'dynamic_days':
          this.dateType_col_1 = 'dynamic_days';
          this.toggle_exclusive_col_1 = 1;
          this.dynamicTime_col_1 = 'Days';
          break;
        case 'dynamic_hours':
          this.dateType_col_1 = 'dynamic_hours';
          this.toggle_exclusive_col_1 = 2;
          this.dynamicTime_col_1 = 'Hours';
          break;
        case 'dynamic_minutes':
          this.dateType_col_1 = 'dynamic_minutes';
          this.toggle_exclusive_col_1 = 3;
          this.dynamicTime_col_1 = 'Minutes';
          break;
        default:
          this.dateType_col_1 = 'dynamic_months';
          this.toggle_exclusive_col_1 = 0;
          this.dynamicTime_col_1 = 'Months';
      }

      switch (this.wsConfigFiltered.animation_limit_date_type) {
        case 'dynamic_days':
          this.dateType_col_2 = 'dynamic_days';
          this.toggle_exclusive_col_2 = 1;
          this.dynamicTime_col_2 = 'Days';
          break;
        case 'dynamic_hours':
          this.dateType_col_2 = 'dynamic_hours';
          this.toggle_exclusive_col_2 = 2;
          this.dynamicTime_col_2 = 'Hours';
          break;
        case 'dynamic_minutes':
          this.dateType_col_2 = 'dynamic_minutes';
          this.toggle_exclusive_col_2 = 3;
          this.dynamicTime_col_2 = 'Minutes';
          break;
        default:
          this.dateType_col_2 = 'dynamic_months';
          this.toggle_exclusive_col_2 = 0;
          this.dynamicTime_col_2 = 'Months';
      }

      switch (this.wsConfigFiltered.refresh_interval_step_type) {
        case 'dynamic_hours':
          this.refreshIntervalStepType = 'dynamic_hours';
          this.toggle_exclusive_col_3 = 0;
          this.refreshIntervalType = 'Hours';
          break;
        case 'dynamic_minutes':
          this.refreshIntervalStepType = 'dynamic_minutes';
          this.toggle_exclusive_col_3 = 1;
          this.refreshIntervalType = 'Minutes';
          break;
        default:
          this.refreshIntervalStepType = 'dynamic_seconds';
          this.toggle_exclusive_col_3 = 2;
          this.refreshIntervalType = 'Seconds';
          break;
      }
    },
    clickMonth(col_name) {
      if (col_name === 'col_1') {
        this.dynamicTime_col_1 = 'Months';
        this.animationStep = '';
        this.dateType_col_1 = 'dynamic_months';
      } else {
        this.dynamicTime_col_2 = 'Months';
        this.animationLimitPast = '';
        this.animationLimitFuture = '';
        this.dateType_col_2 = 'dynamic_months';
      }
    },
    clickDay(col_name) {
      if (col_name === 'col_1') {
        this.dynamicTime_col_1 = 'Days';
        this.animationStep = '';
        this.dateType_col_1 = 'dynamic_days';
      } else {
        this.dynamicTime_col_2 = 'Days';
        this.animationLimitPast = '';
        this.animationLimitFuture = '';
        this.dateType_col_2 = 'dynamic_days';
      }
    },
    clickHour(col_name) {
      if (col_name === 'col_1') {
        this.dynamicTime_col_1 = 'Hours';
        this.animationStep = '';
        this.dateType_col_1 = 'dynamic_hours';
      }

      if (col_name === 'col_2') {
        this.dynamicTime_col_2 = 'Hours';
        this.animationLimitPast = '';
        this.animationLimitFuture = '';
        this.dateType_col_2 = 'dynamic_hours';
      }

      if (col_name === 'col_3') {
        this.refreshIntervalType = 'Hours';
        this.refreshIntervalStep = '';
        this.refreshIntervalStepType = 'dynamic_hours';
      }
    },
    clickMinutes(col_name) {
      if (col_name === 'col_1') {
        this.dynamicTime_col_1 = 'Minutes';
        this.animationStep = '';
        this.dateType_col_1 = 'dynamic_minutes';
      }

      if (col_name === 'col_2') {
        this.dynamicTime_col_2 = 'Minutes';
        this.animationLimitPast = '';
        this.animationLimitFuture = '';
        this.dateType_col_2 = 'dynamic_minutes';
      }

      if (col_name === 'col_3') {
        this.refreshIntervalType = 'Minutes';
        this.refreshIntervalStep = '';
        this.refreshIntervalStepType = 'dynamic_minutes';
      }
    },
    clickSeconds(col_name) {
      if (col_name === 'col_3') {
        this.refreshIntervalType = 'Seconds';
        this.refreshIntervalStep = '';
        this.refreshIntervalStepType = 'dynamic_seconds';
      }
    },
    savePastTime(col_name) {
      if (col_name === 'col_1') {
        this.wsLayersSelectedShallow.forEach(ws => {
          this.wsLayersSelected.forEach(selectedWs => {
            if (ws.ws_name === selectedWs.ws_name && ws.ws_group === selectedWs.ws_group) {
              selectedWs.animation_step = this.animationStep;
              selectedWs.animation_step_date_type = this.dateType_col_1;
            }
          });
        });
      }

      if (col_name === 'col_2') {
        this.wsLayersSelectedShallow.forEach(ws => {
          this.wsLayersSelected.forEach(selectedWs => {
            if (ws.ws_name === selectedWs.ws_name && ws.ws_group === selectedWs.ws_group) {
              selectedWs.animation_limit_past = this.animationLimitPast;
              selectedWs.animation_limit_date_type = this.dateType_col_2;
              selectedWs.archive_start_date = this.startDateArchive;
            }
          });
        });
      }

      if (col_name === 'col_3') {
        this.wsLayersSelectedShallow.forEach(ws => {
          this.wsLayersSelected.forEach(selectedWs => {
            if (ws.ws_name === selectedWs.ws_name && ws.ws_group === selectedWs.ws_group) {
              selectedWs.refresh_interval_step = this.refreshIntervalStep;
              selectedWs.refresh_interval_step_type = this.refreshIntervalStepType;
            }
          });
        });
      }
    },
    saveFutureTime() {
      this.wsLayersSelectedShallow.forEach(ws => {
        this.wsLayersSelected.forEach(selectedWs => {
          if (ws.ws_name === selectedWs.ws_name && ws.ws_group === selectedWs.ws_group) {
            selectedWs.animation_limit_future = this.animationLimitFuture;
            selectedWs.animation_limit_date_type = this.dateType_col_2;
          }
        });
      });
    },
    saveArchiveDateStart(date) {
      this.$refs.menuStartArchive.save(date);
      this.wsLayersSelectedShallow.forEach(ws => {
        this.wsLayersSelected.forEach(selectedWs => {
          if (ws.ws_name === selectedWs.ws_name && ws.ws_group === selectedWs.ws_group) {
            selectedWs.archive_start_date = date;
            selectedWs.animation_limit_past = '';
            selectedWs.animation_limit_date_type = '';
          }
        });
      });
    }
  }
};
</script>
